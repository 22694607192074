<template>
  <div class="placeOrigin">
    <!-- 搜索框 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <!-- 列表 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button
          size="small"
          type="primary"
          @click="editAndAddRow()"
        >
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 编辑and新增弹出层 -->
    <Dialog
      ref="dialog"
      :edit-form-data="editFormData"
      :form-item="formItem"
      dialog-width="35%"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination2.vue'
import Dialog from '@/components/Dialog.vue'
import Table from '@/components/Table'
import FormSearch from '@/components/FormSearch'
import { rules } from '@/utils/matchFormRegex'
import {
  getGoodsProductionPage,
  goodsProductionDelete,
  addAndEditGoodsProduction
} from '@/api/goods'
export default {
  components: { Pagination, Table, Dialog, FormSearch },
  data() {
    return {
      total: 0,
      formInline: {
        pageNum: 1,
        pageSize: 10
      },
      formItemArr: [
        { type: 'input', label: '品牌+产地', value: 'brandProduction' },
        {
          type: 'select',
          label: '支持自提',
          value: 'isDelivery',
          pLabel: 'label',
          pValue: 'value',
          child: [
            { label: '是', value: 1 },
            { label: '否', value: 0 }
          ]
        },
        { type: 'input', label: '公司名称', value: 'companyName' }
      ],
      listData: [],
      formItem: [
        {
          type: 'input',
          value: 'brandProduction',
          label: '品牌+产地',
          rules: [
            { required: true, message: '请输入', trigger: ['blur', 'change'] },
            { min: 1, max: 15, message: '不超过15个字符', trigger: 'blur' }
          ]
        },
        {
          type: 'input',
          value: 'businessGoods',
          label: '主营产品',
          rules: [
            { required: true, message: '请输入', trigger: ['blur', 'change'] },
            { min: 1, max: 32, message: '不超过32个字符', trigger: 'blur' }
          ]
        },
        {
          type: 'input',
          value: 'companyName',
          label: '公司名称',
          rules: [...rules.name]
        },
        {
          type: 'radio',
          value: 'isDelivery',
          label: '支持自提',
          child: [
            { label: '否', value: 0 },
            { label: '是', value: 1 }
          ]
        },
        {
          type: 'radio',
          value: 'state',
          label: '状态',
          child: [
            { label: '无效', value: 0 },
            { label: '有效', value: 1 }
          ]
        }
      ],
      itemData: [
        {
          label: '品牌+产地',
          prop: 'brandProduction'
        },
        {
          label: '主营产品',
          prop: 'businessGoods'
        },
        {
          label: '支持自提',
          prop: 'isDelivery',
          child: [
            { value: '1', label: '是' },
            { value: '0', label: '否' }
          ]
        },
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '状态',
          prop: 'state',
          child: [
            { value: '1', label: '有效' },
            { value: '0', label: '无效' }
          ]
        }
      ],
      editFormData: {},
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        {
          num: 0,
          val: 'state',
          bType: 'success',
          label: '启用',
          handleEvent: this.disableEnabledRow
        },
        {
          num: 1,
          val: 'state',
          bType: 'danger',
          label: '禁用',
          handleEvent: this.disableEnabledRow
        }
      ],
      loading: false
    }
  },
  methods: {
    getFormData(item) {
      addAndEditGoodsProduction(item, () => {
        this.$refs.dialog.editFormVisible = false
        this.$message.success('成功！')
        this.getdata()
      })
    },
    // 获取列表
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      getGoodsProductionPage(this.formInline, res => {
        this.total = res.data.total
        this.listData = [...res.data.pageData]
      })
    },
    // 修改和编辑
    editAndAddRow(row) {
      this.$refs.dialog.editFormVisible = true
      this.editFormData = row ? { ...row } : {}
    },
    // 禁用与启用
    disableEnabledRow(row) {
      this.$confirm('此操作将修改产地状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          goodsProductionDelete(
            { id: row.id, state: row.state === 0 ? 1 : 0 },
            () => {
              this.$message.success('成功')
              this.getdata()
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleEdit() {}
  }
}
</script>

<style lang="scss" scoped>
.form-search {
  margin-top: 20px;
}
.form-search .el-input,
.form-search .el-select {
  width: 180px;
}
.buttonCombination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  font-size: 14px;
  border: 1px solid #eee;
  border-bottom: 0;
  padding: 0 16px;
  .el-button {
    margin-top: 12px;
  }
}
</style>
